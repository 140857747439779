@import './variables';
@import './swiper';
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './button';
@import './simpleBar.css';
/*сброс стилен начало*/
body{
    margin: 0;
    padding: 0;
    width: 100%;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
h1, h2, h3{
    margin: 0;
    padding: 0;
}
*{
    font-family: 'Inter Tight', sans-serif;
    line-height: 1.4;
    box-sizing: border-box;
    color:#000000;
    font-weight: 400;

    margin: 0;
    padding: 0;
}
blockquote{
    margin: 0px;
}
img{
    max-width: 100%;
}
a{
    text-decoration: none !important;
}
input::-ms-clear {
    display: none;
}
input{
    outline: none;
    padding: 0;
}
button{
    outline: none;
    border:none;
}
.icon{
    display: flex;
}
.container::after{
    display: none;
}
.container::before{
    display: none;
}
.container{
    padding: 0;
}



body{
    background-color: $color-fon;
}

ul,
li,
dl {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

p {
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

/*сброс стилен конец*/


.bs-st{
    background-attachment: scroll;
    background-position: 50%;
    background-repeat: none;
    background-size: cover;
    -o-background-size: cover;
}


@media (min-width: 768px){
    html {
        font-size: calc(.01111111*100vw + 0px);
    }
}
@media (max-width: 767px){
    html {
        font-size: calc(.01111111 * 100vw + 0px);
    }
}

.container{
    width: 78.624rem;
    margin-left: auto;
    margin-right: auto;
}
// @media (max-width: 767px){
//     .container {
//         width: 78rem;
//     }
// }

.all-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
}

.all-section{
    max-height: 100vh;
    overflow: initial;
    height: 100%;
}
.all-section-scroll{
    width:100%;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.header{
    width: 100%;
    position: relative;
    display: flex;
    padding-top: $px30;
    padding-bottom: $px30;
}

.header-content{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}
.header-logo{
    width: 12.8232rem;
    display: flex;
}
.header-menu-top-item-l{
    width: $px24;
}
.header-menu-top-item-r{
    font-size: $px16;
    
    color: $color-565656;
}
.header-menu-top-item{
    display: flex;
    align-items:center;
    justify-content: flex-start;
    gap: $px6;
}
.header-menu{
    width: 39.8736rem;
    display: flex;
    flex-direction: column;
}
.header-menu-top{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.header-menu-bottom{
    width: 100%;
    display: flex;
    margin-top: $px30;
    align-items:center;
}
.header-menu-nav{
    width: 100%;
    display: flex;
}
.header-menu-nav ul{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}
.header-menu-nav ul li a{
    color: $color-841;
    font-size: $px20;
    font-family: 'buyan';
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Buyan';
}
.header-menu-nav ul li:last-child{
    margin-right: 1rem;
}
.button-style-1-color{
    background: rgba(0, 107, 17, 1);
    color:#fff;
}
.button-style-1-f{
    font-size: $px16;
    font-weight: 500;
    text-transform: uppercase;
}
.button-style-1-mp{
    padding-left: $px60;
    padding-right: $px60;
    padding-top: $px24;
    padding-bottom: $px24;
    line-height: 0.77;
}
.button-style-1-br{
    border-radius: $px10;
}
 
.header-button{
    width: auto;
   
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}
.header-button-icon{
    gap: 0.5rem;
    display: flex;
    align-items:center;
    margin-bottom: 0.5rem;
}
.header-button-icon .icon{
    position: relative;
    width: $px12;
}

.header-button-text{
    font-size: $px14;
    text-wrap: nowrap;
    color: $color-1107233;
} 

@media (min-width: 768px) and (max-width: 1200px){
    html {
        font-size: calc(.01111111 * 100vw + 2.5px);
    }
    .container{
        width: 67rem;
    }
}
@media (min-width: 768px){
    .dinon768{
        display: none !important;
    }
}

@media (max-width: 767px){
    .header-menu{
        position: fixed;
        left: 0px;
        top:35rem;
        max-height: calc(100vh - 35rem);
        height: calc(100vh - 35rem);
        overflow: auto;
        padding-top: 3rem;
        padding-bottom: 6rem;
        width: 100%;
        z-index: 33;
        background: $color-fon;
    }
    .header-menu-top{
        flex-direction: column;
    }
    .header-menu-top-item-r{
        font-size: $px16mob;
    }
    .header-menu-top-item-l {
        width: $px20mob;
    }
    .header-menu-top-item {
        gap: 1.5rem;
    }
    .header-menu-top{
        gap:5rem;
    }
    .header-menu-bottom{
        margin-top: 0rem;
        margin-bottom: 11rem;
        order: -1;
    }
    .header-menu-nav ul{
        flex-direction: column;
        gap:3rem;
    }
    .header-menu-nav ul li a{
        font-size: $px24mob;
    } 
    .dinon767{
        display: none !important;
    }



    .header-mob-menu-toggle{
        position: relative;
        display: flex;
        right: 5.4rem;
        align-items:center;
        justify-content: center;
    }
    .header-mob-menu-toggle .icon{
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
        transition: all 0.3s;
    }
    .header-mob-menu-toggle.active .icon{
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(180deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }
    .header-mob-menu-toggle.active .icon svg path{
        fill:$color-20911126;
    }
    .button-style-1-mp{
        width: 92%;
        height: 16.8rem;
       
    }
    .button-style-1-f{
        font-size: $px16mob;
    }
    .button-style-1-br{
        border-radius:2.8rem;
    }
    .header-button-text{
        font-size: $px14mob;
    }
    .header-button-icon .icon {
        width: 3.9616rem;
    }
    .header-button-icon{
        margin-bottom: 2.4rem;
    }
    .header-button-icon {
        gap: 2.5rem;
    }
    .header-button{
        margin-top: 11rem;
    }
    .header-mob-menu-toggle .icon{
        width: $px60mob;
        
        position: absolute;
    }
    .mob-menu{
        width: 6rem;
        height: 5rem;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: all 0.3s;
        justify-content: space-between;
       
    }
    
    .mob-menu div{
        transition: all 0.3s;
        width: 100%;
        height: 3px;
        border-radius:3px;
        position: relative;
        background:#fff;
        transform: rotate(0deg);
        top:0;
        opacity: 1;
    }

    .mob-menu-active div:nth-child(2){
        opacity: 0;
    }
    .mob-menu-active div:nth-child(1){
        transform: rotate(45deg);
        top: 2.37rem;
    }
    .mob-menu-active div:nth-child(3){
        transform: rotate(-45deg);
        top: -2.25rem;
    }
    .mob-menu-active div{
        width: 110%;
    }

    .header {
        padding-top: 4rem;
        padding-bottom: 10.404rem;
        z-index: 444;
    }
    .header-logo {
        width: 39.2rem;
    }
    .container {
        width: 84rem;
    }

    .header-menu{
        z-index: -1;
        pointer-events: none;
        transition: all 0.3s;
        opacity: 0;
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(90deg);
        &.active{
            z-index: 1;
            pointer-events: auto;
            opacity: 1;
            transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
        }
    }
    
}
.icon svg{
    height: auto;
    height: fit-content;
    width: 100%;
}
.icon img{
    height: auto;
    height: fit-content;
    width: 100%;
}

section{
    position: relative;
    width: 100%;
}
.content{
    width: 100%;
    position: relative;
}
.section-big-slider{
    padding-top: $px30;
    padding-bottom: $px60;
} 

.big-slider-content{
    overflow: hidden;
}

.big-slider{
    width: 100%;
    position: relative;
}
.swiper { 
    width: 100%;
    height: 100%;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .swiper-button-prev-home{
    position: absolute;
    bottom: calc(50% - 23px);
    width: 46px;
    display: flex;
    z-index: 2;
    left: 1.4rem;
    cursor: pointer;
}
// .swiper-button-prev-home *{
//     transition: all 0.4s !important;
// }
// .swiper-button-prev-home:hover circle{
//     fill:#fff;
// }
// .swiper-button-prev-home:hover path{
//     fill:#000;
// }

.swiper-button-next-home{
    display: flex;
    position: absolute;
    bottom: calc(50% - 23px);
    z-index: 2;
    right: 1.4rem;
    width: 46px;
    left: auto;
    cursor: pointer;
}
// .swiper-button-next-home *{
//     transition: all 0.4s !important;
// }
// .swiper-button-next-home:hover circle{
//     fill:#fff;
// }
// .swiper-button-next-home:hover path{
//     fill:#000;
// }
.swiper-button-disabled{
    opacity: 0.3;
}


.swiper-pagination-1 .swiper-pagination-bullet{
    background: rgba(255, 255, 255, 1) !important;
    opacity: 1;
    border-radius: 10px;
    width: 20px;
    height: 10px;
    transition: all 0.3s;
}
.swiper-pagination-1 .swiper-pagination-bullet-active{
    width: 40px;
    height: 10px;
    background: rgba(255, 172, 22, 1) !important;
    opacity: 1;
    border-radius: 10px;
}
.swiper-pagination-1{
    bottom:20px !important;
    top:auto !important;
    display: none;
}
.swiper-pagination-2{
    top:20px !important;
    bottom:auto !important;
}
.swiper-slide{
    position: relative;

    display: flex;
    justify-content: center;
}
.section-big-slider .swiper-slide{
    border-radius:$px20;
    overflow: hidden;
}
.button-style-2-color{
    color:$color-010717;
    background:#fff;
}
.big-slider .button-style-2-color{
    position: absolute;
    bottom: 6rem;
}

.slider-big-dannie-slidov{
    position: absolute;
    top:20px;
    z-index: 3;
    display: flex;
    align-items:center;
    gap:1rem;
}
.slider-big-dannie-slidov-item{
    border: 2px solid $color-010717;
    color: $color-010717;
    background:#fff;
    border-radius:$px10;
    padding: 0.3rem 0.7rem;
    user-select: none;
    cursor: pointer;
    width: fit-content;
    font-weight: 700;
    transition: all 0.3s;
    &.active{
        background:$color-010717;
        color: #fff;
    }
    &:hover{
        background:$color-010717;
        color: #fff;
    }
    font-size: $px16;
}
.big-slider{
    display: flex;
    justify-content: center;

}

.big-slider-fon{
    display: flex;
    position: absolute;
    z-index: 0;
    width: 17.8776rem;
    top:11rem;
} 
.container{
    position: relative;
    z-index: 2;
}
.big-slider-fon img{
    width: 100%;
}
.section-big-slider .container{
    display: flex;
    justify-content: center;
}

.section-big-slider .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px;
}
@media (max-width: 767px){
    .section-big-slider {
        & .swiper-button-next-home{
            display: none;
        }
        & .swiper-button-prev-home{
            display: none;
        }
        
        & .container{
            width: 100%;
        }
    }
    
    .slider-big-dannie-slidov-item{
        font-size: $px16mob;
        border-radius:$px10mob;
        padding: 2rem 4rem;
    }
    .slider-big-dannie-slidov {
        gap: 3rem;
    }

    .section-big-slider {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .big-slider-fon {
        width: 58rem;
        bottom: -47rem;
        top:auto;
    }
    .section-big-slider .swiper-slide{
        border-radius:0;
    }
    .swiper-pagination-1 {
        bottom: -29px !important;
    }
}

.title-st-1{
    width: 100%;
    text-align: center;
    color: $color-841;
    font-size: $px60;
    font-family: 'buyan';
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Buyan';
}
.section-slider{
    padding-top: 2rem;    
    padding-bottom: 6rem;
}
.section-slider-battons{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: auto;
   
    top: 0px;
    margin-top: 1.3rem;
    max-width: 62rem;
    align-items:center;
    justify-content: center;
    flex-wrap: wrap;
}
.section-slider-battons-mob{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    align-items:center;
    justify-content: center;
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
}
.section-slider-batton-open .icon{
    width: 4.48rem;
}
.section-slider-batton-open{
    display: flex;
    align-items:center;
    justify-content: center;
}
.section-slider-batton-open{
    background: rgba(0, 107, 17, 1);
    border-radius:$px10mob;
    gap:3rem;
    width: 100%;
}
.section-slider-batton-open span{
    color: #fff;
    font-weight: 700;
    font-size: $px16mob;
}

.section-slider-content{
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.section-slider-content-left{
    width: 50%;
    padding-right: 1.25rem;
    align-items:flex-start;
    display: flex;
    flex-direction: column;
}

.title-st-2{
    width: fit-content;
    display: flex;
    align-items:center;
    gap:0.8rem;
    color: rgba(0, 107, 17, 1);
    font-weight: 600;
    text-transform: uppercase;
}
.title-st-2-text{
    font-size: $px30;
    line-height: 0.77;
    color: rgba(0, 107, 17, 1);
    font-weight: 600;
    text-transform: uppercase;
}
.text-blok-sl p{
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: $px16;
    color: $color-565656;
}
.section-slider-content-blok{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap:0.3rem;
}
.section-slider-content-blok-2{
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}
#root{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.text-blok-sl .title-st-2{
    margin-bottom: 1.5rem;
} 
.title-st-3{
    color: $color-565656;
    font-weight: 600;
    font-size: $px30;
}
.section-slider-content-blok-2 *{
    width: 100%;
}
.section-slider-content-blok-2{
    gap:0.3rem;
    & .section-slider-content-blok-2-left{
        width: fit-content;
        gap:0.3rem;
        display: flex;
        flex-direction: column;
        margin-right: 2.5rem;
    }
    & .section-slider-content-blok-2-right{
        width: fit-content;
        gap:0.3rem;
        display: flex;
        flex-direction: column;
    }
    
}

.section-slider-slider{
    width: 100%;
    
    position: relative;
    display: flex;
    margin-top: 4.5rem;
}
.section-slider-slider .swiper-slide img{
    border-radius:$px20;
}
.section-slider-slider-fon{
    position: absolute;
    left: -10px;
    top:10px;
    background: rgba(255, 255, 255, 1);
    opacity: 0.5;
    height: 100%;
    border-radius:$px30;
    width: 100%;
}
.section-slider-slider-buttons .swiper-button-prev-home {
    position: absolute;
    right: 5.4rem;
    left: auto;
    top:auto;
    bottom:1.4rem;
}
.section-slider-slider-buttons .swiper-button-next-home {
    position: absolute;
    right: 1.6rem;
    top:auto;
    bottom:1.4rem;
    left: auto;
}
.section-slider-slider-buttons-2 .swiper-button-next-home {
    left: 5.4rem;
    right: auto;
}
.section-slider-slider-buttons-2 .swiper-button-prev-home {
    left: 1.6rem;
    right: auto;
}

.section-slider-content-right{
    width: 50%;
    padding-left: 1.25rem;
    align-items:flex-start;
    display: flex;
    flex-direction: column;
}

.section-slider-content-right-st-pl .section-slider-slider-2{
    margin-left: 7rem;
}
.section-slider-slider-2 {
    width: calc(100% - 7rem);
    position: relative;
    display: flex;
}
.swiper *{
    user-select: none;
}
.title-st-2 .icon{
    width: 3.042rem;
}
.text-blok-sl-p{
    gap: 0.8rem;
    display: flex;
    margin-top: 5.2rem;
    flex-direction: column;
}
.text-blok-sl-p p{
    font-size: $px16;
    color: $color-565656;
}
.section-slider-slider-fon-2{
    position: absolute;
    right: -10px;
    top:10px;
    background: rgba(255, 255, 255, 1);
    opacity: 0.5;
    height: 100%;
    border-radius:$px30;
    width: 100%;
}

@media (max-width: 767px){
    .title-st-1{
        font-size: $px40mob;
        line-height: 1.1;
    }
    .section-slider {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }

    .section-slider-battons{
        padding-top: 5rem;
    }
    .section-slider-battons{
        width: 100%;
        max-width: 100%;
    }
    .section-slider-battons-mob{
        width: 100%;
        border: 2px solid rgba(0, 107, 17, 1);
        background:#fff;
        border-bottom-left-radius: $px20mob;
        border-bottom-right-radius: $px20mob;
        overflow: hidden;
        margin-top: -2rem;
        position: relative;
        z-index: 1;
        padding-top: 10rem;
    }
    .section-slider-battons-mob .slider-big-dannie-slidov-item{
        padding: 1rem 4rem;
        width: calc(50% - 1.5rem);
    }
    
    .section-slider-battons-mob {
        justify-content: flex-start;
        gap: 3rem;
        top:16rem;
        position: absolute;
        z-index: -1;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s;
        &.active{
            pointer-events: auto;
            opacity: 1;
            z-index: 1;
        }
    }
    .section-slider-batton-open *{
        transition: all 0.3s;
    }
    .section-slider-batton-open{
        & .icon{
            transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
        }
    }
    
    .section-slider-batton-open.active{
        background:$color-010717;
        & .icon{
            transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(180deg) rotateY(0deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
        }
    }
    .section-slider-battons-mob-fon{
        position:absolute;
        width: 55%;
        display: flex;
        align-items:center;
        right: 0px;
        left: auto;
        top: auto;
        bottom: 0px;
        & img{
            width: 100%;
        }
    }
    .section-slider-battons-mob {
        padding-bottom: 56rem;
    }
    
    .slider-big-dannie-slidov-item {
        border: 0px solid rgb(0, 107, 17);
    }
    .section-slider-batton-open {
        position: relative;
        z-index: 2;
        height: 11.76rem;
    }
    .slider-big-dannie-slidov-mob {
        gap: 0rem;
       
    }
    // .slider-big-dannie-slidov-mob .section-slider-battons-mob{
    //     display: none;
    // }
    .section-slider-content-left {
        width: 100%;
        padding-right: 0rem;
        
    }
    .title-st-2 .icon{
        width: $px50mob;
    }
    .title-st-2-text{
        font-size: $px24mob;
    }
    .title-st-2 {
        gap: 2.8rem;
    }
    .section-slider-content {
        margin-top: 14.5rem;
    }
    .text-blok-sl p{
        font-size: $px16mob;
    }
    .text-blok-sl p {
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
    }
    .text-blok-sl .title-st-2 {
        margin-bottom: 4rem;
    }
    .title-st-3{
        font-size: $px24mob;
        line-height: 1.11;
        text-transform: uppercase;
        padding-right: 1rem;
    }
    .section-slider-content-blok {
        gap: 1.3rem;
    }
    .section-slider-content-blok {
        margin-top: 13rem;
    }
    .section-slider-slider {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .section-slider-content-blok-2 .section-slider-content-blok-2-left{
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .section-slider-content-right {
        width: 100%;
    }
    .section-slider-content-right {
        width: 100%;
        padding-left: 0rem;
    }
    .section-slider-content-right-st-pl .section-slider-slider-2 {
        width: 100%;
        margin-left: 0;
    }
    .section-slider-content-right{
        margin-top: 9rem;
    }

    .section-slider-slider-buttons .swiper-button-prev-home{
        display: none;
    }
    .section-slider-slider-buttons .swiper-button-next-home{
        display: none;
    }
    .swiper-pagination-2{
        display: flex;
        align-items: center;
        height: fit-content;
        top: auto !important;
        z-index: 33;
    }
    .swiper-pagination-2 .swiper-pagination-bullet {
        margin: 0 4px;
    }
    .section-slider .swiper{
        padding-bottom: 40px;
        align-items: center;
        justify-content: center;
        display: flex;
    
    }
    .swiper-pagination-2 {
        bottom: 5px !important;
    }
    .section-slider-slider-fon {
        border-radius:$px10mob;
        left: 10px;
        top: 10px;
        height: calc(100% - 40px);
    }
    .section-slider-slider-fon-2{
        border-radius:$px10mob;
        left: 10px;
        top: 10px;
        height: calc(100% - 40px);
    }
    .text-blok-sl-p p{
        font-size: $px14mob;
    }
    .text-blok-sl-p {
        gap: 4rem;
    }
    .section-slider-slider {
        width: calc(100% - 10px);
    }
    .mob-or--6{
        order: -6;
    }
    .mob-or--7{
        order: -7;
    }
    .mob-or--8{
        order: -8;
    }
    .mob-or--5{
        order: -5;
    }
    
    .slider-big-dannie-slidov-item:hover{
        background:#fff;
        color: $color-010717;
    }
    .section-slider-batton-open:hover{
        background:$color-010717;
    }
} 
.cas-100df{
    width: 100%;
    display: flex;
    flex-direction: column;
}
 
@media (min-width: 768px) and (max-width: 990px){
    .section-slider-slider-buttons-2 .swiper-button-prev-home {
        left: 1.1rem;
    }
    .section-slider-slider-buttons .swiper-button-next-home {
        right: 1.1rem;
    }
}

@media (min-width: 768px){
    .swiper-pagination-2{
        display: none;
    }
    .section-slider-batton.section-slider-batton-open.slider-big-dannie-slidov-item{
        display: none;
    }
    .section-slider-battons-mob-fon{
        display: none;
    }
    .section-slider-battons-mob {
        gap: 1rem;
    }
}


.section-slider-2{
    padding-top: 3rem;
    z-index: 3;
}
.section-slider-2-fon{
    background: linear-gradient(181.34deg, rgba(255, 255, 255, 0) 2.29%, #FFFFFF 7.35%);
    position: absolute;
    top:10rem;
    height: calc(100% - 10rem);
    width: 100%;
    left: 0px;
    z-index:1;
}
.section-slider-2 .section-slider-slider {
    margin-top: 0rem;
}
.section-slider-2 .text-blok-sl-p {
    margin-top: 2rem;
}
.section-slider-content-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.section-slider-content-container .section-slider-content:nth-child(2n - 1) .section-slider-content-left {
    order: 2;
    padding-left: 1.3rem;
    padding-right: 0;
}
.section-slider-content-container .section-slider-content:nth-child(2n - 1) .swiper-button-next-home {
    left: 5.4rem;
    right: auto;
}
.section-slider-content-container .section-slider-content:nth-child(2n - 1) .swiper-button-prev-home {
    left: 1.6rem;
    right: auto;
}
.section-slider-content-container .section-slider-content:nth-child(2n - 1) .section-slider-content-right {
    padding-left: 0rem;
    padding-right: 1.25rem;
}
.section-slider-content-container .section-slider-content:nth-child(2n - 1) .section-slider-slider-fon {
    right: -10px !important;
    left: auto !important;
}
.section-slider-content-container .section-slider-content .section-slider-slider-fon {
    background: rgba(238, 229, 222, 1);
}


.section-slider-2 .section-slider-content-right.section-slider-content-right-st-pl{
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 767px){
    .section-slider-content-left.text-blok-sl{
        order: -2 !important;
    }
    .section-slider-2 .text-blok-sl .title-st-2 {
        margin-bottom: 6rem;
    }
    .section-slider-content-container .section-slider-content:nth-child(2n-1) .section-slider-content-left {
        padding-left: 0;
    }
    .section-slider-2 .section-slider-slider {
        margin-bottom: 4.5rem;
    }
    .section-slider-2 .title-st-2-text{
        font-size: $px18mob;
        line-height: 1;
    }
}
.section-slider .container{
    z-index: 4;
}
.section-slider-2 .container{
    z-index: 4;
}
.section-slider-section-slider-2-fon{
    position: relative;
    top:0%;
    height: 0px;
    width: 100%;
    z-index: 2;
    & .sizejsimg{
        width: 105%;
        left: 0%;
        position: absolute;
        height: 53.5rem;
        top: -39rem;
    }
}
 
.section-slider-2-fon-2{
    position: absolute;
    z-index: 2;
    left: 0px;
    top: -12rem;
    width: 15.912rem;
}
.section-slider-fon-1{
    position: absolute;
    z-index: 2;
    left: auto;
    right: 0px;
    bottom: -3rem;
    width: 18rem;
}
.section-slider-2-fon-3{
    position: absolute;
    right: -6rem;
    top: -11.5rem;
    z-index: -1;
    width: 12.402rem;
}
.section-slider-2-fon-4{
    top: 71.3rem;
    right: 21.5rem;
    position: absolute;
    z-index: 2;
    width: 34.2rem;
}
// .section-slider-2-fon-4{
//     top: auto;
//     bottom:3rem;
//     right: auto;
//     left: 0;
//     position: absolute;
//     z-index: 2;
//     width: 34.2rem;
// }
.section-slider-2-fon-5{
    top: auto;
    bottom:-8rem;
    right: auto;
    left: 0;
    position: absolute;
    z-index: 2;
    width: 35rem;
}
.section-slider-2 .text-blok-sl-p p{
    background:rgb(255, 255, 255, 0.7); 
}

.section-slider-2 .title-st-2.dinon767{
    width: 100%;
    position: relative;
}
.title-st-2.dinon768{
    width: 100%;
    position: relative;
}
@media (max-width: 767px){
    .section-slider-section-slider-2-fon .sizejsimg {
        height: 127.5rem;
        top: -124rem;
    }
    .section-slider-2-fon {
        top: -41rem;
        height: 67%;
    }
    .section-slider-fon-1 {
        z-index: 4;
        right: -103px;
        bottom: -8rem;
        width: 69rem;
    }
    .section-slider-2-fon-2{
        display: none;
    }
    // .section-slider.section-slider-2{
    //     background: linear-gradient(181.34deg, rgba(255, 255, 255, 0) 2.29%, #FFFFFF 7.35%);
    // }
    .section-slider-slider .swiper-slide img{
        border-radius:$px10mob;
    }
    .section-slider-2-fon-3 {
        right: -16px;
        top: -17rem;
        width: 19.402rem;
    }
    .section-slider-2-fon-3 img{
        width: 100%;
    }
    .section-slider-2-fon-5 {
        bottom: 79rem;
        left: 0;
        width: 39rem;
    }
}
@media (min-width: 768px) and (max-width: 990px){
    .section-slider-content-container .section-slider-content:nth-child(2n-1) .swiper-button-prev-home {
        left: 1.1rem;
    }
}

.order-0-01{
    order: 0 !important;
}
.about{
    background:#fff;
}
.ubout-text{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:0.6rem;
    align-items:flex-start;
}
.title-st-4{
    text-transform: uppercase;
    line-height: 1.1;
    margin-bottom: 0.3rem;
    font-size: $px30;
    color: $color-565656;
    font-weight: 600;
}
.ubout-text .title-st-4{
    margin-bottom: 1.1rem;
}
.ubout-text ul{
    padding-left: 0.4rem;
    gap:0.7rem;
    display: flex;
    flex-direction: column;
}
.ubout-text li::after{
    content: '';
    width: 4px;
    height: 4px;
    border-radius:50%;
    position: absolute;
    left: -10px;
    background:$color-565656;
    top: 0.44rem;
}
.ubout-text .title-st-4-2{
    margin-bottom: -0.2rem;
    margin-top: 1.3rem;
}
.ubout-text li{
    font-size: $px16;
    position: relative;
    margin-left: 12px;
}
.ubout-text-3-l{
    max-width: 17.7rem;
    display: flex;
    flex-direction: column;
}
.ubout-text-3{
    margin-top: 1.3rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items:flex-start;
}
.ubout-text-3-ul{
    margin-top: 0.5rem;
    gap:0.35rem !important;
}
.ubout-text-3-r{
    padding-top: 3rem;
    width: 16.6rem;
    margin-left: auto;
}
.fw500{
    font-weight:500;
}
.ubout-text-4{
    margin-top: 1.3rem;
    display:flex;
    flex-direction: column;
    gap:0.6rem;
}

.ubout-text-4 .header-menu-top{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    gap:0.6rem;
}
.ubout-text-4 .header-menu-top span{
    color: $color-1107233;
    font-size: $px16;
    margin-left: 0.5rem;
}
.ubout-text-4 .header-menu-top *{
    text-wrap: nowrap;
}

.video-1 video{
    object-fit: cover;
    width: 80%;
    height: 47.3rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: $px20;
    cursor: pointer;
}
.video-1{
    position: relative;
    display: flex;
    align-items:center;
    justify-content: center;
}
.video-1-play{
    position: absolute;
    z-index: 3;
    cursor: pointer;
}

.about-fon {
    position: absolute;
    right: 0rem;
    bottom: -19rem;
    display: flex;
    width: 27.5184rem;
}
.pb03-pr{
    padding: 0.3rem 1.3rem;
}

@media (min-width: 768px) and (max-width: 1200px){
    .ubout-text-3-r {
        width: 13.6rem;
    }
}

.about{
    background:#fff !important;
}

.ubout-text p{
    font-size: $px16;
}
@media (max-width: 1200px){
    .title-st-4 br{
        display: none;
    }
}
@media (max-width: 767px){
    .about .section-slider-content-right.section-slider-content-right-st-pl{
        order: -3;
    }
   
    .section-slider-2 .swiper-pagination-1 .swiper-pagination-bullet{
        background: rgba(238, 229, 222, 1) !important;
    }
    .section-slider-2 {
        padding-top: 8rem;
    }
    
    .section-slider-2 .title-st-2-text{
        line-height: 1.1;
    }
    .section-slider-2 .swiper-pagination-1 .swiper-pagination-bullet-active{
        background: rgba(255, 172, 22, 1) !important;
    }
    .title-st-4{
        font-size: $px24mob;
        font-weight: 700;
    }
    .ubout-text li{
        font-size: $px16mob;
    }
    .ubout-text p{
        font-size: $px16mob;
    }
    .ubout-text li::after {
        top: 2.74rem;
    }
    .ubout-text {
        gap: 2.8rem;
    }
    .ubout-text-3-l{
        width: 100%;
        max-width: 100%;
    }
    .ubout-text-4 .header-menu-top span{
        font-size: $px16mob;
    }
    .video-1 video {
        width: 100%;
        height: auto;
    }
    .section-slider-content-left.text-blok-sl.video-1{
        margin-top: 13rem;
    }
    .video-1 video{
        border-radius: $px20mob;
    }
    .ubout-text-3-r.dinon768{
        margin-top: 8rem;
        width: 100%;
    }
    .about-fon {
        bottom: -24rem;
        width: 45.5184rem;
    }
}
.about-fon img{
    width: 100%;
}
.order-0-03{
    order:0 !important;
}
.arenda{
    z-index: auto;
    background:rgb(238, 229, 222);
}
.arenda-fon-1{
    position: absolute;
    right: 0rem;
    bottom: -14rem;
    z-index: 1;
    width: 28.8288rem;
}
.arenda-fon-2{
    width: 12.7296rem;
    position: absolute;
    z-index: 1;
    bottom: -5rem;
    left: 11rem;
}


.arenda-text .title-st-4 {
    margin-bottom: 0.3rem;
    margin-top: 1.1rem;
}
.arenda-text .pb03-pr{
    margin-top: 1.8rem;
}

.arenda-text ul {
    gap: 0.2rem;
    margin-bottom: 1.1rem;
}

.arenda-text{
    gap: 1.1rem
}
.arenda .container{
    position: relative;
    z-index: 3;
}

@media (max-width: 767px){
    // .section-slider.section-slider-2.arenda{
    //     background:#fff;
    // }
    .arenda .section-slider-content-left.text-blok-sl{
        order: 3 !important;
    }
    .arenda-text {
        gap: 3.4rem;
    }
    .arenda .section-slider-content {
        margin-top: 4.5rem;
    }
    .arenda-text .title-st-4 {
        margin-bottom: 1.3rem;
        margin-top: 5.1rem;
    }
    .arenda-text ul {
        gap: 2.2rem;
    }
    .arenda.section-slider-2 .section-slider-slider {
        margin-bottom: 0rem;
    }
    .arenda .swiper-pagination-1 {
        bottom: 0px !important;
    }
    .arenda.section-slider-2 .swiper-pagination-1 .swiper-pagination-bullet{
        margin-left: 2px;
        margin-right: 2px;
    }

    .arenda-fon-1 {
        left: 0rem;
        bottom: -21rem;
        width: 40.8288rem;
    }
    .arenda-fon-2 {
        left: auto;
        bottom: 5rem;
        right: 2rem;
        width: 22.8288rem;
    }
    .arenda .section-slider-slider .swiper-slide img{
        border-radius:$px10mob;
    }
}
.arenda-fon-1 img{
    width: 100%;
}
.arenda-fon-2 img{
    width: 100%;
}

.faq{
    z-index: 0 !important;
    background:#fff;
}

.faq .section-slider-content-container{
    margin-top: 1.4rem;
}
.faq-blok-1{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin-top: 0.44rem;
    margin-bottom: 1.3rem;
}
.faq .title-st-2.dinon767{
    margin-bottom: 0.66rem; 
}
.faq .title-st-2-text{
    line-height: 1.14;
}
.faq-blok-1 li::after{
    display: none;
}
.faq-blok-1 li{
    display: flex;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    align-items:center;
    margin-left: 0;
    padding-left: 0;
}
.faq-blok-1 li div:first-child{
    position: absolute;
    display: flex;
    right: auto;
    align-items: center;
    justify-content: center;
    width: 3.042rem;
}
.faq-blok-1 li div:nth-child(2){
    padding-left: 3.44rem;
}
.faq-blok-1 li div:nth-child(2) span{
    font-weight: 600;
}
.faq-blok-1 li div:nth-child(2) span{
    font-size: $px18;
}
.faq-blok-1 li div:nth-child(2){
    font-size: $px18;
    color:$color-841;
}
// .header-mob-menu-toggle .icon {
//     width: 6.8rem;
//     position: absolute;
// }
.faq-blok-1 ul{
    margin-left: 0;
    padding-left: 0;
    gap:1.44rem;
}
.faq-blok-1 li div:first-child span{
    font-weight:700;
    color:#fff;
    font-size: $px30;
    position: relative;
    z-index: 2;
}
.faq-fon{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}
.faq .section-slider-content-left.text-blok-sl img{
    border-radius:$px20;
    position: relative;
    z-index: 2;
}
.faq .section-slider-content-left {
    padding-left: 0rem !important;
   

}
.faq .section-slider-slider-fon{
    background: rgba(238, 229, 222, 1);
}

.dinon{
    display: none !important;
}
.faq-blok-1-tab-2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:0.6rem;
    align-items:flex-start;
}
.faq-blok-1-tab-1{
    gap:0.6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}
.title-st-2 .icon{
    flex-shrink:0;
}
.faq-blok-1-tab-2 li div:nth-child(2){
    font-weight: 700 !important;
}
.faq-blok-1-tab-2 li div:first-child {
    right: 0px;
    left:auto !important;
}
.faq-blok-1-tab-2 li div:nth-child(2) {
    padding-right: 3.44rem;
    padding-left: 0px;
}
.faq-blok-1-tab-2 li{
    width: 100%;
    flex-wrap: wrap;
}
.faq-blok-1-tab-2 li div:first-child{
    top:0px;
}
.fac-blok-js li div:first-child {
    top: -0.4rem;
    height: 2.7rem;
}
.fac-blok-js li div:first-child span{
    top: -0.4rem;
}
.fac-blok-js *{
    transition: all 0.3s;
}
.faq-blok-1-tab-2 li div:nth-child(2) span{
    position: absolute;
}
.faq-blok-1-tab-2 ul{
    width: 100%;
}
.faq-blok-1{
    width: 100%;
}
.faq-blok-1-tab-2 .faq-blok-1{
    width: 85%;
}
.faq-text-04{
    padding-right: 5.44rem;
    margin-top: 0.6rem;
    font-size: $px16;
}
.faq-text-04{
    max-height: 0px;
    overflow: hidden;
}
.fac-blok-js li.active .icon {
    transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(180deg) rotateY(0deg) rotateZ(180deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
}
.fac-blok-js li.active .icon svg path {
    fill: rgb(209, 111, 26);
}
.fac-blok-js li.active span svg {
    transform: rotate(45deg);
}
.fac-blok-js li.active span {
    line-height: 0;
}
.fac .section-slider-content-right.section-slider-content-right-st-pl{
    margin-top: 0;
}
.fac-blok-js li{
    cursor: pointer;
}
.faq{
    padding-bottom: 6.5rem;
}
@media (max-width: 767px){
    .faq{
        background:#fff !important;
        padding-top: 17rem;
    }
    .faq-blok-1-tab-2{
        display: flex !important;
    }
    .faq-blok-1 li div:first-child {
        width: 14.042rem;
    }
    .faq-blok-1 li div:first-child span{
        font-size: $px24mob;
    }
    .faq-blok-1 li {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .faq-blok-1 {
        margin-top: 4.44rem;
        margin-bottom: 12.3rem;
    }
    .faq-blok-1 li {
        min-height: 14rem;
    }
    .faq-blok-1 li div:nth-child(2) {
        padding-left: 17.44rem;
    }
    .faq-blok-1 li div:nth-child(2){
        font-size: $px18mob;
    }
    .faq-blok-1 li div:nth-child(2) span{
        font-size: $px18mob;
    }
    .faq-abz-as-04{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .faq-abz-as-04-title{
        margin-bottom: 11rem !important;
    }
    .faq-blok-1-tab-2.dinon{
        margin-top: 15rem;
    }
    .faq-blok-1-tab-2 .faq-blok-1 {
        width: 100%;
    }
    .faq-blok-1-tab-2 li div:nth-child(2) {
        padding-right: 17rem;
        padding-left: 0px;
    }
    .fac-blok-js li div:first-child {
        top: 2.6rem;
    }
    .fac-blok-js li div:first-child span {
        top: 2.6rem;
    }
    .faq-text-04{
        font-size: $px16mob;
    }
    .faq-text-04 {
        margin-top: 3.6rem;
    }
    .fac-blok-js li {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    .faq-blok-1 {
        margin-bottom: 10.3rem;
    }
    .faq .section-slider-content-left.text-blok-sl img{
        border-radius:$px10mob;
    }
    .faq-fon {
        height: 59%;
        top: auto;
        bottom: 0;
    }
    .faq{
        padding-bottom: 26rem !important;
    }
    .faq .order4min768{
        margin-top: 0 !important;
        padding-top: 0 !important;
        order: 4 !important;
    }
}

.footer{
    width: 100%;
    position: relative;
    background: rgba(56, 56, 56, 1);
    padding-top: 5rem;
    padding-bottom: 4rem;
    z-index: 4;
}
.footer-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}
.footer-content-left{
    width: 18rem;
}
.footer-content-right{
    width: calc(100% - 18rem);
}
.footer-content-left-licenziy{
    font-size: $px16;
    color:#fff;
    margin-top: 2.5rem;
}
.footer-content-right{
    width: 53rem;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}
.footer-content-right ul li a{
    color:#fff;
    
}
.footer-content-right a{
    color:#fff;
}
.footer-content-right .header-menu-top-item:nth-child(1) path{
    fill:#fff;
}
.footer-content-right .header-menu-top-item:nth-child(2) path{
    fill:#fff;
}
.footer-content-right .header-menu-top-item:nth-child(3) path{
    fill:#fff;
}
.footer-menu-pr-3422{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-menu-pr-3422 span{
    color: rgba(199, 199, 199, 1) !important;
    margin-top: 0.15rem;
    font-size: $px16;
}

.footer-content-right-3{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer-content-right{
    gap:1.1rem;
}
.footer-content-right .header-menu-bottom {
    margin-top: 1rem;
}
.footer-content-right-3-st{
    color: rgba(199, 199, 199, 1) !important;
    font-size: $px16;
    margin-top: 0.85rem;
}
.footer-content-right .header-menu-nav ul li a{
    font-size: $px24;
}
.footer-content-right .header-menu-nav ul li:last-child {
    margin-right: 0rem;
}
.fac-blok-js li div:first-child span{
    line-height: 0;
}
@media (max-width: 1200px) and (min-width: 768px){
    .footer-content-right {
        width: 48rem;
    }
    
}
@media (max-width: 1200px){
    .footer-content-left-licenziy br{
        display: none;
    }
}
@media (max-width: 767px){
    .footer-content-left {
        width: 100%;
    }
    .footer-content-left-licenziy{
        font-size: $px16mob;
        text-align: center;
        width: 100%;
    }
    .footer .header-logo{
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        padding-top: 18rem;
        padding-bottom: 18rem;
    }
    .footer-content-left-licenziy {
        margin-top: 7.5rem;
    }
    .footer-content-right .header-menu-nav ul li a{
        font-size: $px24mob;
    }
    .footer-content-right {
        width: 100%;
    }
    .footer-content-right{
        margin-top: 13rem;
    }
    .footer-menu-pr-3422 span{
        font-size: $px16mob;
    }
    .footer-content-right-3{
        flex-direction: column;
        margin-top: 11rem;
        gap:3rem;
    }
    .footer-content-right-3-st{
        text-align: center;
        width: 100%;
        font-size: $px16mob;
    }
    span.footer-content-right-3-st{
        order: 3;
        padding-left: 17rem;
        padding-right: 17rem;
    }

} 

.arenda-fon-3{
    position: absolute;
    right: 0rem;
    bottom: 12rem;
    width: 34rem;
}
.arenda-har{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0.7rem;
    margin-bottom: 0.1rem;
}
.arenda-har-text{
    font-size: $px16;
    color:$color-565656;
    font-weight: 400;
}
.arenda-har-text span{
    font-size: $px18;
    color:$color-565656;
    font-weight: 600;
}
.title-hat-arenda-page{
    margin-top: 1.88rem;
    margin-bottom: 0.85rem;
    
    & .title-st-2-text{
        line-height: 1.01;
    }
} 

.st-pt-05-as{
    margin-top: 1.5rem;
}

@media (max-width: 767px){
    .arenda-fon-3 {
        width: 61rem;
        bottom: -20rem;
    }
    .arenda-fon-3 img{
        width: 100%;
    }
    .title-hat-arenda-page {
        margin-top: 9.5rem;
        margin-bottom: 0.5rem;
    }
    .ubout-text p{
        margin-top: 9.5rem;
    }
    .st-pt-05-as {
        margin-top: 9rem;
    }
    .swiper-pagination-1 {
        display: flex;
    }
    .section-slider-2 .swiper-pagination-1 .swiper-pagination-bullet {
        background: #fff !important;
    }
    .section-slider-2 .swiper-pagination-1 .swiper-pagination-bullet-active {
        background: rgb(255, 172, 22) !important;
    }
    .section-slider-content-container .section-slider-content .section-slider-slider-fon {
        background: #fff;
        opacity: 1;
    }
    .title-hat-arenda-page .title-st-2-text {
        line-height: 1.2;
        font-size: $px24mob;
    }
    .arenda-har-text{
        font-size: $px18mob;
    }
    .arenda-har-text span{
        font-size: $px18mob;
    }
    .arenda-har {
        gap: 3.7rem;
    }
    .flstst-05-ocp{
        align-items:flex-start !important;
    }
}

.icon svg{
    overflow: initial;
}
.header-menu-nav a.active{
    color: rgba(209, 111, 26, 1);
}
.header-menu-nav a:hover{
    color: rgba(209, 111, 26, 1);
}


.icontell *{
    transition: all 0.3s;
}
.header-menu-top-item:not(:hover) .icontell path:nth-child(2){
    opacity: 0;
}
.header-menu-top-item:not(:hover) .icontell path:nth-child(1){
    fill: #383838;
}
.header-menu-top-item:hover a{
    color: rgba(209, 111, 26, 1);
    // font-weight: 700;
}
a{
    transition: all 0.3s;
}

.iconmail *{
    transition: all 0.3s;
}
.header-menu-top-item:hover .iconmail path{
    fill: rgba(209, 111, 26, 1);
}


.instaicon *{
    transition: all 0.3s;
}
.header-menu-top-item:hover .instaicon path{
    stroke: rgba(209, 111, 26, 1);
}


.header-button *{
    transition: all 0.3s;
}
.header-button:hover .button-style-1-color{
    background: rgba(209, 111, 26, 1);

}
.header-button:hover .header-button-text{
    color: rgba(209, 111, 26, 1);
}

.button-style-2-color.button-style-1-mp.button-style-1-f.button-style-1-br{
    transition: all 0.3s;
}
.button-style-2-color.button-style-1-mp.button-style-1-f.button-style-1-br:hover{
    background: rgba(209, 111, 26, 1);
    color:#fff;
}

.footer-content-right-3-st{
    transition: all 0.3s;
}
a.footer-content-right-3-st:hover{
    color: rgb(209, 111, 26) !important;
}

.button-style-2-color.button-style-1-mp.button-style-1-f.button-style-1-br{
    cursor: pointer;
}


/**/
.simplebar-track.simplebar-vertical {
    background: #fff;
    border-radius: 0px !important;
}

.simplebar-scrollbar:before {
    border-radius: 0px !important;
    background: rgb(0, 107, 17) !important;
    color: rgb(0, 107, 17) !important;
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: 1 !important;
    border-radius: 0px !important;
}
@media (min-width: 768px){
    .simplebar-track.simplebar-vertical {
        width: 11px;
    }
    .simplebar-scrollbar:before {
        left: 2px;
        right: 2px;
    }
   
    .all-section-scroll {
        padding-right: 11px;
    }
}
@media (max-width: 767px){
    .simplebar-track.simplebar-vertical {
        width: 7px !important;
    }
    .simplebar-scrollbar:before {
        left: 1px;
        right: 1px;
    }
   
    .all-section-scroll {
        padding-right: 7px !important;
    }

}
/**/
.up-top{
    position: fixed;
    left: 0.7rem;
    bottom: 1.4rem;
    width: 2.7rem;
    z-index: 15;
    cursor: pointer;
    transition: all 0.3s;
}
.up-top svg{
    width: 100%;
}
.up-top-none{
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.button-style-1-color.button-style-1-mp.button-style-1-f.button-style-1-br{
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
}
.Hidden{
    overflow: hidden;
    max-height: 100vh;
}

@media (max-width: 767px){
    .up-top {
        width: 7.7rem;
    }

    #root .section-slider-battons-mob .slider-big-dannie-slidov-item.active {
        background: #fff;
        color: rgb(0, 107, 17);
    }
}

@media (min-width: 768px){
    .arenda-fon-3 {
        bottom: auto;
        top: 16rem;
    }
}
